<template>
  <CButton
    :color="color"
    :class="{ 'default': color === '' }"
    shape="pill"
    variant="outline"
    @click="openSupportModal"
  >
    <slot>Update Information</slot>
  </CButton>
</template>

<script>
export default {
  name: 'RChangeRequestButton',
  props: {
    color: {
      type: String,
      default: ''
    },
    beforeOpen: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    openSupportModal () {
      this.beforeOpen()
      this.$store.dispatch('support/open')
    }
  }
}
</script>

<style lang="scss" scoped>
.default:hover {
  background-color: $gray-50;
}
</style>
