<template>
  <div class="checkbox-button">
    <CButton
      shape="pill"
      :color="checked ? color : ''"
      :pressed="checked"
      @click="$emit('update:checked', !checked)"
    >
      <slot />
    </CButton>
  </div>
</template>

<script>

/**
 * Wrapper component for a pill shaped CButton that when clicked,
 * applies a color attribute and emits its new state back up the chain;
 * Set the .sync modifier on checked to receive state changes. See:
 * https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier
 *
 * Events:
 *
 * @update:checked: triggered when the button is clicked
 */
export default {
  name: 'RCheckboxButton',
  props: {
    color: {
      type: String,
      default: 'info'
    },
    checked: {
      type: Boolean,
      required: true
    }
  }
}
</script>
