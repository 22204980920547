<template>
  <div class="toggle-group">
    <RCheckboxButton
      v-for="(option, index) in options"
      :key="option.value"
      :checked="selected.includes(option.value)"
      :class="{'ml-2': index > 0, 'mr-2': index < options.length - 1}"
      @update:checked="checked => handleCheck(checked, option.value)"
    >
      {{ option.label }}
    </RCheckboxButton>
  </div>
</template>

<script>

/**
 * Wrapper component for a group of RCheckboxButtons.
 *
 * Events:
 *
 * @change: triggered when any of the buttons are toggled
 */
import { RCheckboxButton } from '@/components/buttons'

export default {
  name: 'RButtonGroup',
  components: {
    RCheckboxButton
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * handler for toggling a button in the group
     */
    handleCheck (checked, item) {
      const newSelected = [...this.selected]
      if (checked) {
        newSelected.push(item)
      } else {
        newSelected.splice(this.selected.indexOf(item), 1)
      }
      this.$emit('change', newSelected)
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-group {
  display: flex;
}
</style>
