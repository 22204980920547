<template>
  <div class="r-button vld-parent" style="display: inline-block;">
    <RSpinnerOverlay
      :loading="loading"
      :opacity="0.7"
      :height="height"
    >
      <CButton :class="{ 'btn-link': isLinkVariant }" v-bind="buttonProps" v-on="$listeners">
        <slot />
      </CButton>
    </RSpinnerOverlay>
  </div>
</template>

<script>
/**
 * Wrapper component for CoreUI's CButton which adds
 * a loading overlay option controlled by the `loading`
 * prop.
 */
import { RSpinnerOverlay } from '@/components/spinners'

export default {
  name: 'RButton',
  components: {
    RSpinnerOverlay
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    height () {
      switch (this.$attrs.size) {
        case 'sm':
          return 15
        case 'lg':
          return 25
        default:
          return 20
      }
    },
    buttonProps () {
      const attrs = Object.assign({}, this.$attrs)
      if (this.isLinkVariant) {
        delete attrs.variant
      }
      return attrs
    },
    isLinkVariant () {
      return this.$attrs.variant && this.$attrs.variant === 'link'
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-link {
  border: none !important;
  color: $info;
  font-size: 1rem;
}
</style>
